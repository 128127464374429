<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.shopExpenses') }}</h5>
                <div class="p-grid">
                    <div class="p-col-6 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shop') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-10 p-text-right"><Button type="button" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <div class="card" v-if="showReportData">
                    <DataTable :value="reportData" ref="datatable" responsiveLayout="scroll" v-model:expandedRows="expandedRows">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column v-if="hasAccess(availableMenus, editPage)" :expander="true" headerStyle="width: 3rem" />
                        <Column :header="$t('sportsSettings.name')" field="planId">
                            <template #body="slotProps"> {{ collaborationPlansDictionary[slotProps.data.planId] }}</template></Column
                        >
                        <Column field="startMonth" :header="$t('collaborationPlans.startMonth')"> </Column>
                        <Column :header="$t('collaborationPlans.startYear')" field="startYear"> </Column>
                        <Column :header="$t('collaborationPlans.endMonth')" field="endMonth">
                            <template #body="slotProps"> {{ slotProps.data.endMonth == 0 ? 'Active' : slotProps.data.endMonth }}</template>
                        </Column>
                        <Column :header="$t('collaborationPlans.endYear')" field="endYear">
                            <template #body="slotProps"> {{ slotProps.data.endYear == 0 ? 'Active' : slotProps.data.endYear }}</template>
                        </Column>
                        <template #expansion="slotProps">
                            <CollaborationPlanHistoryItem
                                @refresh="refresh"
                                :planId="slotProps.data._id"
                                :startMonth="slotProps.data.startMonth"
                                :endMonth="slotProps.data.endMonth"
                                :startYear="slotProps.data.startYear"
                                :endYear="slotProps.data.endYear"
                            ></CollaborationPlanHistoryItem>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../../service/BoApi';
import CollaborationPlanHistoryItem from './Components/CollaborationPlanHistoryItem.vue';
export default {
    name: 'collaborationPlanHistory',
    components: {
        CollaborationPlanHistoryItem,
    },
    data() {
        return {
            reportData: [],
            BoApi: new BoApi(),
            showReportData: false,
            expenseDate: null,
            errorKeyCount: 0,
            showError: false,
            shops: [],
            shopsModel: { name: 'All', _id: 'all' },
            collaborationPlans: [],
            disableSubmit: false,
            crtRoute: 'collaborationPlanHistory',
            collaborationPlansDictionary: [],
            expandedRows: [],
        };
    },
    mounted() {
        this.getShops();
        this.getCollaborationPlans();
    },
    computed: {
        editPage() {
            return this.crtRoute + '/edit';
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    watch: {
        shopsModel() {
            if (!this.notNullOrEmptyObject(this.shopsModel)) {
                this.shopsModel = { name: 'All', _id: 'all' };
            }
        },
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            code: shopsResponse.data[i].code,
                            _id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.addShops = shopsDdlValues;
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        getCollaborationPlans() {
            this.BoApi.getCollaborationPlans()
                .then((response) => {
                    this.collaborationPlans = response.data;
                    this.createPlansDictionary();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        showReport() {
            if (this.shopsModel._id != 'all') {
                this.showReportData = false;
                this.reportData = [];
                this.BoApi.getShopCollaborationPlans(this.shopsModel._id)
                    .then((reportResponse) => {
                        if (reportResponse.data != null) {
                            this.reportData = reportResponse.data;
                            this.tableKey++;
                        }
                        this.showReportData = true;
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorKeyCount++;
                    });
            } else {
                this.showToast('error', this.$t('alerts.selectOneShop'));
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        createPlansDictionary() {
            for (let i = 0; i < this.collaborationPlans.length; i++) {
                this.collaborationPlansDictionary[this.collaborationPlans[i].id] = this.collaborationPlans[i].name;
            }
        },
        refresh() {
            this.showReport();
        },
    },
};
</script>