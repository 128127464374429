<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-grid">
                    <div class="p-col-6 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="this.planDetails.StartMonth" />
                            <label>{{ $t('collaborationPlans.startMonth') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="this.planDetails.StartYear" />
                            <label>{{ $t('collaborationPlans.startYear') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-2">
                        <span class="p-float-label" v-if="this.planDetails.EndMonth != 0">
                            <InputText style="width: 100%" type="text" v-model="this.planDetails.EndMonth" />
                            <label>{{ $t('collaborationPlans.endMonth') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-2">
                        <span class="p-float-label" v-if="this.planDetails.EndYear != 0">
                            <InputText style="width: 100%" type="text" v-model="this.planDetails.EndYear" />
                            <label>{{ $t('collaborationPlans.endYear') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-4 p-text-right">
                        <Button type="button" :label="$t('buttons.save')" class="p-button-raised p-button-warning" @click="this.saveChanges()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../../../service/BoApi';

export default {
    name: 'collaborationPlanHistoryItem',
    props: {
        planId: {
            type: String,
            required: true,
        },
        startMonth: {
            type: Number,
            required: true,
        },
        startYear: {
            type: Number,
            required: true,
        },
        endMonth: {
            type: Number,
            required: true,
        },
        endYear: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            BoApi: new BoApi(),
            planDetails: {},
        };
    },
    emits: ['refresh'],
    mounted() {
        this.planDetails.StartMonth = this.startMonth;
        this.planDetails.EndMonth = this.endMonth;
        this.planDetails.StartYear = this.startYear;
        this.planDetails.EndYear = this.endYear;
        this.planDetails.PlanId = this.planId;
    },
    methods: {
        saveChanges() {
            this.BoApi.saveCollaborationPlansHistory(this.planDetails)
                .then((response) => {
                    this.MessageContent = response.data;
                    this.$emit('refresh');
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
    },
};
</script>